Drupal.behaviors.emailSignupBlockV2 = (function ($, _, site, generic) {
  'use strict';

  var $template = $();
  var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));
  var $siteHeaderSignup = $('.js-site-header__signup-unsigned');
  // Public methods:
  var behavior = {
    attach: function (context) {
      $template = $('.js-email-signup-block-v2', context);
      behavior.initEmailSignup();
      // Check if the user is signed in and if they are a loyalty member
      generic.jsonrpc.fetch({
        method: 'user.fullData',
        params: [{}],
        onSuccess: function (data) {
          var user = data.getValue();

          if (user.has_loyalty_program) {
            behavior.initBBLink();
          } else {
            $siteHeaderSignup.hide();
          }
        }
      });
    },

    initEmailSignup: function () {
      if (!$template.length) {
        return null;
      }
      $template.each(function () {
        var $emailForm = $(this);
        var $emailInput = $('.js-email-signup-block__field', $emailForm);
        var $languageIDInput = $('input[name="LANGUAGE_ID"]', $emailForm);
        var $emailErrorMessage = $('.js-email-signup-block-error', $emailForm);
        var $emailSuccessMessage = $('.js-email-signup-block-success', $emailForm);
        var $emailAlreadySignedUpMessage = $('.js-email-signup-block-already-signed-up', $emailForm);
        var $emailpromoCheckbox = $('.js-email-optin-field', $emailForm);

        if (Drupal.settings.common.has_onelink_trans) {
          if (generic.cookie('LOCALE') === Drupal.settings.common.locate_one_link_translation) {
            $languageIDInput.val(Drupal.settings.common.lang_id_one_link_translation);
          }
        }

        $emailInput.on('keyup', function () {
          if ($(this).val() === '') {
            $(this).removeClass('non-empty');
          } else {
            $(this).addClass('non-empty');
          }
        });

        $emailForm.once('js-email-signup-block-v2').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccessMessage.add($emailErrorMessage).add($emailAlreadySignedUpMessage).addClass('hidden');
          $emailInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          params.PC_EMAIL_PROMOTIONS = 0;
          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          if ($.cookie('csrftoken')) {
            params._TOKEN = $.cookie('csrftoken');
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              if (_.isNull($.cookie('displaySignUp'))) {
                $.cookie('displaySignUp', '1', {
                  expires: 1,
                  path: '/'
                });
              }
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
                $emailAlreadySignedUpMessage.removeClass('hidden');
              } else {
                $(document).trigger('email_signup:success_and_new');
                $emailSuccessMessage.removeClass('hidden');
              }

              // Close active utility items
              site.globalNavigation.closeSubNav(0);
              $emailSuccessMessage.removeClass('hidden');
              $emailInput.val('').removeClass('non-empty');
              if ($emailpromoCheckbox.length > 0) {
                $emailpromoCheckbox.attr('checked', false);
              }
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();
              var errors = '';

              $emailErrorMessage.removeClass('hidden');
              for (var i = 0; i < errObjs.length; i++) {
                var myErr = errObjs[i];

                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup' ||
                    myErr.key === 'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                    $emailInput.addClass('error').focus();
                  }
                }
              }
              if (errors) {
                $emailErrorMessage.html(errors);
              }
            }
          });
        });
      });
    },

    initBBLink: function () {
      // Will validate user signed in or not and change url of bbrewards icon accordingly, in header
      var data_normal_link = $siteHeaderSignup.attr('data-normal-link');
      var data_loyalty_link = $siteHeaderSignup.attr('data-loyalty-link');

      if ($siteHeaderSignup.length) {
        if (signedIn) {
          $siteHeaderSignup.attr('href', data_loyalty_link);
        } else {
          $siteHeaderSignup.attr('href', data_normal_link);
        }
      }
    }
  };

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
